
import { defineComponent, ref, onMounted } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddStockJournalVoucherModal from "@/components/modals/forms/AddStockJournalVoucherModal.vue";
import AddProductListSalesEnquiry from "@/components/modals/forms/AddProductListSalesEnquiry.vue";
// import MixedWidget7 from "@/components/widgets/mixed/Widget7.vue";
import MixedWidget10 from "@/components/widgets/mixed/Widget10.vue";
// import Datatable from "@/components/kt-datatable/KTDatatable.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
// import customers from "@/core/data/customers";
// import roc_list, { business_types } from "@/core/data/genericData";
// import { ICustomer } from "@/core/data/customers";
import { useStore } from "vuex";
// import { Actions } from "@/store/enums/StoreEnums";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
// import { array } from "yup/lib/locale";
// import JwtService from "@/core/services/JwtService";
import { Modal } from "bootstrap";

export default defineComponent({
  name: "customers-listing",
  components: {
    ExportCustomerModal,
    AddStockJournalVoucherModal,
    AddProductListSalesEnquiry,
    // MixedWidget7,
    MixedWidget10,
    // Datatable,
  },

  setup() {
    const count = ref(0);
    const store = useStore();
    const checkedCompany = ref([]);
    const loadingData = ref<boolean>(true);
    const listVisible = ref<boolean>(false);
    const searchTearm = ref("");
    const reason_data = ref([
      {
        stock_movement_reason_id: 1,
        stock_movement_reason: "Stock Inward Adjustment",
      },
      {
        stock_movement_reason_id: 2,
        stock_movement_reason: "Stock Outward Adjustment",
      },
      {
        stock_movement_reason_id: 3,
        stock_movement_reason: "Stock Transfer",
      },
    ]);

    interface WIJournal {
      active: {
        label: string;
        color: string;
      };
      is_approved: {
        label: string;
        color: string;
      };
      stock_journal_voucher_id: number;
      stock_journal_voucher_no: string;
      stock_journal_voucher_date: string;
      stock_movement_reason: string;
      // inward_warehouse_id: string;
      // outward_warehouse_id: string;
      is_transport_applicable: string;
    }
    interface WICompanies {
      company_id: number;
      user: {
        avatar: string;
        name: string;
        group: string;
      };
      comp_type: string;
      status: {
        label: string;
        color: string;
      };
      active: {
        label: string;
        color: string;
      };
      roc: string;
      nob: string;
      doi: string;
      pan: string;
    }

    getReason();

    var paginationData = ref({});

    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      mmaxPageg: 6,
    };

    const refreshData = () => {
      paginationData.value["activePage"] = 1;
      getCompanyList(searchTearm.value);
    };

    const changePageChange = (page) => {
      paginationData.value["activePage"] = page;
      getCompanyList(searchTearm.value);
    };

    var tableData = ref<Array<WIJournal>>([]);

    const getCompanyList = async (data) => {
      loadingData.value = true;

      try {
        var values = {
          page: parseInt(paginationData.value["activePage"]),
          records_per_page: parseInt(paginationData.value["perPage"]),
          // records_per_page: 1000,
        };
        console.log(values);
        await store
          .dispatch(ActionsFi.CUST_GET_STOCK_JOURNAL_VOUCHAR_LIST, values)
          .then((data) => {
            debugger;
            console.log("total data:" + JSON.stringify(data));

            // console.log("total data:" + response.totalCount);
            // const data = response;
            debugger;
            data.data = data.data.map((item) => {
              let reasonValue = reason_data.value.filter((reason) => {
                return (
                  reason.stock_movement_reason_id ===
                  item.stock_movement_reason_id
                );
              });
              item.stock_movement_reason =
                reasonValue[0]?.stock_movement_reason;
              item.is_transport_applicable = item?.is_transport_applicable
                ? "Yes"
                : "No";
              return item;
            });
            tableData.value = [];
            const totalCount = data.count;
            // set pagination
            paginationData.value["total"] = totalCount;
            data = data.data;
            var pg =
              paginationData.value["activePage"] *
              paginationData.value["perPage"];
            paginationData.value["start"] =
              pg - paginationData.value["perPage"];
            paginationData.value["end"] =
              paginationData.value["start"] +
              paginationData.value["perPage"] * 1;

            paginationData.value["totPage"] = Math.ceil(
              totalCount / paginationData.value["perPage"]
            );

            paginationData.value["mmaxPageg"] =
              paginationData.value["totPage"] <
              paginationData.value["mmaxPageg"]
                ? paginationData.value["totPage"]
                : paginationData.value["mmaxPageg"];

            var resultsM = ref<Array<WIJournal>>([]);
            var status_label = "";
            var status_color = "";
            var active_label = "";
            var active_color = "";

            for (let j = 0; j < data.length; j++) {
              if (data[j]["is_approved"]) {
                status_label = "Yes";
                status_color = "success";
              } else {
                status_label = "No";
                status_color = "danger";
              }

              if (data[j]["active"]) {
                active_label = "Yes";
                active_color = "success";
              } else {
                active_label = "No";
                active_color = "danger";
              }

              resultsM.value = Array({
                stock_journal_voucher_no: data[j]["stock_journal_voucher_no"],
                stock_journal_voucher_date:
                  data[j]["stock_journal_voucher_date"],
                stock_movement_reason: data[j]["stock_movement_reason"],
                // outward_warehouse_id: data[j]["outward_warehouse_id"],
                is_transport_applicable: data[j]["is_transport_applicable"],
                stock_journal_voucher_id: data[j]["stock_journal_voucher_id"],
                is_approved: {
                  label: status_label,
                  color: status_color,
                },
                active: {
                  label: active_label,
                  color: active_color,
                },
              });
              console.log("resultsm:" + JSON.stringify(resultsM.value));
              tableData.value.splice(
                j,
                resultsM.value.length,
                ...resultsM.value
              );
            }

            loadingData.value = false;
          })
          .catch(({ response }) => {
            tableData.value = [];
            loadingData.value = false;
          });
      } catch (e) {
        console.log(e);
      }
    };

    // onUpdated(() => {          });

    // var tableData = ref<Array<WICompanies>>([]);
    const initCustomers = ref<Array<WIJournal>>([]);

    onMounted(async () => {
      await getCompanyList(searchTearm.value);
      setCurrentPageBreadcrumbs("Stock Journal Vouchar", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    });

    const deleteFewCustomers = () => {
      checkedCompany.value.forEach((item) => {
        // deleteCustomer(item);
      });
      checkedCompany.value.length = 0;
    };

    // const deleteCustomer = (id) => {
    //   for (let i = 0; i < tableData.value.length; i++) {
    //     if (tableData.value[i].company_id === id) {
    //       tableData.value.splice(i, 1);
    //     }
    //   }
    // };

    const product_add_modal = (sales_enquiry_id) => {
      const modal = new Modal(
        document.getElementById("kt_modal_add_product_sales_enquiry")
      );
      modal.show();
      (document.querySelector("#sales_enquiry_id") as HTMLInputElement).value =
        sales_enquiry_id;
    };

    const search = ref<string>("");
    const searchItems = () => {
      searchTearm.value = search.value;
      getCompanyList(search.value);
      console.log(search.value);
      // tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      // if (search.value !== "") {
      //   let results: Array<WICompanies> = [];
      //   for (let j = 0; j < tableData.value.length; j++) {
      //     if (searchingFunc(tableData.value[j], search.value)) {
      //       results.push(tableData.value[j]);
      //     }
      //   }
      //   tableData.value.splice(0, tableData.value.length, ...results);
      // }
    };

    function showList() {
      listVisible.value = true;
      console.log("list visible.." + showList);
    }
    function hideList() {
      listVisible.value = false;
    }

    async function getReason() {
      await store
        .dispatch(ActionsFi.CUST_GET_REASON)
        .then(({ data }) => {
          reason_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      tableData,
      // deleteCustomer,
      search,
      searchItems,
      checkedCompany,
      reason_data,
      deleteFewCustomers,
      count,
      getCompanyList,
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
      product_add_modal,
      listVisible,
      showList,
      hideList,
    };
  },
});
