
import { defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import roc_list, {
  business_natures,
  group_types,
  industry_types_pc,
  business_types,
} from "@/core/data/genericData";
import axios from "axios";
import { mask } from "vue-the-mask";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import JwtService from "@/core/services/JwtService";
import AddProductDetailsModal from "@/components/modals/forms/AddProductDetailsModal.vue";
import moment from "moment";

// import DataTable from "@/components/custom/Table.vue";

export default defineComponent({
  name: "add-client-modal",
  directives: { mask },
  components: {
    AddProductDetailsModal,

    // DataTable,
  },

  setup() {
    const store = useStore();
    // const nob_data = business_natures;
    // const group_type_data = group_types;
    // const business_types_data = business_types;
    // const roc_list_data = roc_list;
    const group_type_data = ref([]);
    const nob_data = ref([]);
    const business_types_data = ref([]);
    const roc_list_data = ref([]);
    const uom_data = ref([]);
    const inward_warehouse_data = ref([]);
    const outward_warehouse_data = ref([]);
    // const tableData = ref([]);
    const tableData = ref<Array<tableDataRefI>>([]);
    const branch_data = ref([]);

    interface tableDataRefI {
      Product;
      Quantity;
      Batch_no;
      UOM;
      Rate;
    }

    const product_data = ref([
      {
        product_id: 2,
        product_name: "AMNS PPGI Coil IS-2062 E250A",
        product_short_name: "AM/NS PPGI Coil IS-2062 E250A",
      },
      {
        product_id: 1,
        product_name: "JSW PPGI Coil IS-2062 E250 BR",
        product_short_name: "JSW PPGI Coil IS-2062 E250 BR",
      },
    ]);

    const reason_data = ref([
      {
        id: 1,
        name: "Stock Inward Adjustment",
      },
      {
        id: 2,
        name: "Stock Outward Adjustment",
      },
      {
        id: 3,
        name: "Stock Transfer",
      },
    ]);

    // const inward_warehouse_data = ref([
    //   {
    //     id: 1,
    //     name: "Warehouse 1",
    //   },
    //   {
    //     id: 2,
    //     name: "Warehouse 2",
    //   },
    // ]);

    // const outward_warehouse_data = ref([
    //   {
    //     id: 1,
    //     name: "Warehouse 1",
    //   },
    //   {
    //     id: 2,
    //     name: "Warehouse 2",
    //   },
    // ]);

    const transport_applicable_data = ref([
      {
        id: 0,
        name: "No",
      },
      {
        id: 1,
        name: "Yes",
      },
    ]);

    const industry_types_data = industry_types_pc;
    const formRef = ref<null | HTMLFormElement>(null);
    const addStockJournalVoucharRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const router = useRouter();

    getAddData();
    getUOM();
    getReason();
    getProductList();
    getWarehouseList();

    // console.log("industry_types_data");console.log(industry_types_data);

    // interface Iind_ty_pc  {
    //   industry_type_name,
    //   industry_type_id,
    //   parent_industry_id
    // }

    // var industry_types_data = ref<Array<Iind_ty_pc>>([]);
    // var ind_ty_pcT = ref<Array<Iind_ty_pc>>([])
    // var k =  0;

    // for (let j = 0; j < industry_types_pc.length; j++) {

    //     ind_ty_pcT.value = Array({
    //       industry_type_name : industry_types_pc[j]['company_industry_type_name'],
    //       industry_type_id : industry_types_pc[j]['company_industry_type_id'],
    //       parent_industry_id : 0
    //     });

    //   industry_types_data.value.splice(k, ind_ty_pcT.value.length, ...ind_ty_pcT.value);

    //   if(industry_types_pc[j]['subtype']){

    //     console.log(industry_types_pc[j]['subtype']);

    //     for (let i = 0; i < industry_types_pc[j]['subtype'].length; i++) {
    //       ind_ty_pcT.value = Array({
    //         industry_type_name :  industry_types_pc[j]['subtype'][i]['company_industry_type_name'],
    //         industry_type_id :  industry_types_pc[j]['subtype'][i]['company_industry_type_id'],
    //         parent_industry_id : industry_types_pc[j]['subtype'][i]['company_industry_type_id'],
    //       });
    //       k = k+1;
    //       industry_types_data.value.splice(k, ind_ty_pcT.value.length, ...ind_ty_pcT.value);
    //     }

    //   }
    //   k = k+1;
    // }

    function deleteRow(row) {
      myData.value.splice(row, 1);
    }

    const formData = ref({
      name: "",
      reason_select: "",
      sjvd_date: moment().format("YYYY-MM-DD"),
      outward_warehouse_select: "",
      inward_warehouse_select: "",
      transport_applicable_select: 0,
      transportname: "",
      vehicleno: "",
      lrno: "",
    });

    const myData = ref([
      {
        product: "",
        batchno: "",
        quantity: "",
        uom: "",
        rate: "",
        remark: "",
      },
    ]);

    const rules = ref({
      reason_select: [
        {
          required: true,
          message: "Reason is required",
          trigger: "change",
        },
      ],
      outward_warehouse_select: [
        {
          required: true,
          message: "Outward warehouse is required",
          trigger: "change",
        },
      ],
      inward_warehouse_select: [
        {
          required: true,
          message: "inward warehouse is required",
          trigger: "change",
        },
      ],
      transportname: [
        {
          required: true,
          message: "Transport Name is required",
          trigger: "change",
        },
      ],
      vehicleno: [
        {
          required: true,
          message: "vehical No is required",
          trigger: "change",
        },
      ],
      lrno: [
        {
          required: true,
          message: "L.R. No. is required",
          trigger: "change",
        },
      ],
    });

    const setCompanyData = async (data) => {
      // let product_list = [];
      debugger;
      let product_list = myData.value.map((item) => {
        return {
          product_id: item.product,
          product_batch_no: item.batchno,
          product_qunatity: item.quantity,
          uom_id: item.uom,
          product_rate: item.rate,
          product_remark: "",
        };
      });
      let validateProductList = myData.value.filter((item) => {
        return (
          item.product != "" &&
          item.quantity != "" &&
          item.uom != "" &&
          item.rate != ""
        );
      });
      if (
        validateProductList === [] ||
        validateProductList.length != myData.value.length
      ) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Fill All required product fields.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }
      if (product_list.length <= 0) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Add At Least One Product.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }
      const db_data = {
        stock_movement_reason_id: data.reason_select,
        inward_warehouse_id:
          data.reason_select in [1, 3] ? data.inward_warehouse_select : 0,
        outward_warehouse_id:
          data.reason_select in [2, 3] ? data.outward_warehouse_select : 0,
        is_transport_applicable:
          data.transport_applicable_select === 1 ? true : false,
        transport_name: data.reason_select === 3 ? data.transportname : "",
        transport_vehicle_no: data.reason_select === 3 ? data.vehicleno : "",
        transport_lr_no: data.reason_select === 3 ? data.lrno : "",
        transport_lr_date: data.sjvd_date,
        product_details: product_list,
        // product_details: [
        //   {
        //     product_id: "1",
        //     product_batch_no: "",
        //     product_qunatity: 100,
        //     uom_id: "1",
        //     product_rate: 500,
        //     product_remark: "",
        //   },
        //   {
        //     product_id: "2",
        //     product_batch_no: "",
        //     product_qunatity: 200,
        //     uom_id: "1",
        //     product_rate: 60,
        //     product_remark: "",
        //   },
        // ],
        is_approved: true,
        created_user_id: "1",
        created_ip: "0.0.0.0",
        created_uagent: "Test",
        active: true,
      };
      console.log(JSON.stringify(db_data));
      try {
        await axios
          .post(
            "https://elogicalservices.mymetal.in/mm_stock_journal_vouchers/create",
            db_data,
            {
              headers: {
                "x-api-key": "4HTvrHxuh98GvP3XWYXaUYMHgDnOuMa1aHXtDD95",
              },
            }
          )
          .then(({ data }) => {
            loading.value = false;
            console.log(data);
            setTimeout(() => {
              loading.value = false;

              Swal.fire({
                text: "Form has been successfully submitted!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addStockJournalVoucharRef.value);
              });
            }, 2000);
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    };

    // const setCompanyData = async (data) => {
    //   var user = JSON.parse(JwtService.getToken());

    //   var dt = ("0" + data.incorporationateDate.getDate()).slice(-2);
    //   var mo = ("0" + (data.incorporationateDate.getMonth() + 1)).slice(-2);
    //   var yy = data.incorporationateDate.getFullYear();
    //   var dtt = yy + "-" + mo + "-" + dt;
    //   var cinLlpin = data.llpinn === "" ? data.cinn : data.llpinn;

    //   const db_data = {
    //     company_name: data.name,
    //     company_name_alternative: data.alternativename,
    //     company_nob: data.nob_select,
    //     company_group_type: data.group_type_select,
    //     company_industry_type: data.industry_type_select,
    //     company_business_type: data.business_type_select,
    //     company_roc: data.roc_select === "" ? 0 : data.roc_select,
    //     company_doi: dtt,
    //     company_cin_llpin: cinLlpin === "" ? 0 : cinLlpin,
    //     company_registration_number:
    //       data.registrationNo === "" ? 0 : data.registrationNo,
    //     company_pan: data.pan,
    //     company_website: data.website,
    //     company_verified_yes_no: false,
    //     active: true,
    //     created_user_id: user.user_id,
    //   };

    //   console.log(db_data);

    //   await store
    //     .dispatch(Actions.CUST_ADD_COMPANY, db_data)
    //     .then(({ data }) => {
    //       console.log(data);

    //       if (data.company_id) {
    //         setTimeout(() => {
    //           loading.value = false;
    //           //
    //           Swal.fire({
    //             text: "Company has been successfully created.",
    //             icon: "success",
    //             buttonsStyling: false,
    //             confirmButtonText: "Ok, got it!",
    //             customClass: {
    //               confirmButton: "btn btn-primary",
    //             },
    //           }).then(() => {
    //             hideModal(addCompanyModalRef.value);
    //             router.go(0);
    //           });
    //         }, 2000);
    //       } else {
    //         loading.value = false;

    //         Swal.fire({
    //           text: "Sorry, looks like there are some errors detected, please try again.",
    //           icon: "error",
    //           buttonsStyling: false,
    //           confirmButtonText: "Ok, got it!",
    //           customClass: {
    //             confirmButton: "btn btn-primary",
    //           },
    //         });
    //         return false;
    //       }
    //     })
    //     .catch(({ response }) => {
    //       console.log(response);
    //     });
    // };

    const submit = async () => {
      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    async function getUOM() {
      await store
        .dispatch(ActionsFi.CUST_GET_UOM)
        .then(({ data }) => {
          uom_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
    function Required() {
      return { color: "red" };
    }
    async function getReason() {
      await store
        .dispatch(ActionsFi.CUST_GET_REASON)
        .then(({ data }) => {
          reason_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getProductList() {
      var values = { search_term: "", company_id: 0 };
      await store
        .dispatch(Actions.CUST_GET_PRODUCT_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          product_data.value = data;
          // console.log(JSON.stringify(data.data.result_list));
          // console.log(JSON.stringify(product_data.value));
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    function logReason() {
      console.log("reason is..." + formData.value["reason_select"]);
    }

    function addProduct() {
      // tableData.value.push({
      //   Product: "test",
      //   Quantity: "3",
      //   Rate: "4",
      //   Batch_no: "3",
      //   UOM: "kg",
      // });
      myData.value.push({
        product: "",
        batchno: "",
        quantity: "",
        uom: "",
        rate: "",
        remark: "",
      });
      //   console.log("table data.." + tableData.value);
    }

    async function getWarehouseList() {
      let values = {
        company_id: "1",
        // page: 1,
        // records_per_page: 10,
      };
      await store
        .dispatch(Actions.CUST_GET_WAREHOUSE_LIST, values)
        .then(({ data }) => {
          debugger;
          inward_warehouse_data.value = data;
          outward_warehouse_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }
    async function getAddData() {
      await store
        .dispatch(Actions.CUST_GET_COMPANY_PAGE_DATA)
        .then(({ body }) => {
          nob_data.value = body.business_natures;
          group_type_data.value = body.group_types;
          group_type_data.value.splice(0, 1);
          business_types_data.value = body.business_types;
          roc_list_data.value = body.roc_list;
          roc_list_data.value.splice(0, 1);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    return {
      group_type_data,
      industry_types_data,
      business_types_data,
      roc_list_data,
      formData,
      nob_data,
      rules,
      submit,
      formRef,
      loading,
      addStockJournalVoucharRef,
      uom_data,
      reason_data,
      logReason,
      transport_applicable_data,
      tableData,
      addProduct,
      myData,
      product_data,
      deleteRow,
      getReason,
      getProductList,
      branch_data,
      inward_warehouse_data,
      outward_warehouse_data,
      Required,
      getWarehouseList,
    };
  },
});
